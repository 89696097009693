import React, { useEffect, Fragment } from 'react'
import './App.css'
import { useAuth0 } from '@auth0/auth0-react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import DocumentContainer from './components/DocumentContainer'
import FullPageSpinner from './components/FullPageSpinner'
import './css/FullPageSpinner.css'

function App() {
    const authCtx = useAuth0()
    const { isAuthenticated, isLoading } = authCtx
    const location = useLocation()
    const validPaths = ['/', '/apidocs', '/login']
    const baseURL = 'https://apidocs.wishfarms.com'
    const Docs = () => {
        return (
            <DocumentContainer />
        )
    }
    
    useEffect(() => {
        if (!validPaths.includes(location.pathname)) {
            window.location.replace(baseURL)
        }
    })
    const Login = () => {
    
        const auth = useAuth0()
        const { loginWithRedirect } = auth
    
        useEffect(() => {
            console.log('LOGGING IN...')
            loginWithRedirect()
        }, [])
    
        return (
            <Fragment>
                {isLoading && (
                    <FullPageSpinner message='loading...' />
                )}
            </Fragment>
        )
    }

    return (
            <Switch>
                <Route exact path='/'>
                    {!isLoading ? (
                        <Fragment>
                            {isAuthenticated ? (
                                <Redirect to={{pathname: '/apidocs'}}  />
                            ) : (
                                <Redirect to={{pathname: '/login'}} />
                            )}
                        </Fragment>
                    ): (
                        <FullPageSpinner message='loading...' />
                    )}
                </Route>
                <Route path='/apidocs'>
                    {isAuthenticated ? (
                        <Docs />
                    ): (
                        <Redirect to={{pathname: '/login'}} />
                    )}
                </Route>
                <Route path='/login'>
                    <Login />
                </Route>
            </Switch>
    )
}



export default App;
