import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react'
import { BrowserRouter as Router } from 'react-router-dom'
import './index.css';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
        <Auth0Provider
            domain="wishfl.us.auth0.com"
            clientId="VuX0r9nkk62j0n57LrnecgzsfCc3yUbU"
            redirectUri={window.location.origin}
        >
            <Router>
                <App />
            </Router>
        </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);


