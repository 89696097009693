import React from 'react'
import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'
import apiDoc from '../apidocs/openapi-fielduser.json'

const DocumentContainer = () => {

    return (
        <SwaggerUI spec={apiDoc} />
    )
}

export default DocumentContainer